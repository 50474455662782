import React, { useState } from "react";

import { Box, Tooltip, Paper, Skeleton, CardContent, Card, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { green, red, grey } from '@mui/material/colors';

const LoadingSkeleton = () => {
    return (
        <Card variant="outlined" sx={{ maxWidth: 545, marginBottom: 2, marginLeft: "200px", marginTop: "20px" }}>
            <CardContent sx={{ marginLeft: "20px" }}>
                <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
                    <Skeleton variant="text" width={200} height={30} />
                    <Skeleton variant="text" width={160} height={10} />
                    <Skeleton variant="text" width={160} height={10} sx={{ marginBottom: 2 }} />
                    {/* Timeline skeleton */}
                    <Box sx={{ position: 'relative', marginBottom: 4 }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: '8px',
                                bottom: 0,
                                width: '2px',
                                backgroundColor: grey[300],
                                zIndex: 0,
                            }}
                        ></Box>

                        {/* Skeleton for each step */}
                        {[...Array(3)].map((_, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 3,
                                    position: 'relative',
                                    zIndex: 1,
                                }}
                            >
                                {/* Skeleton for timeline point */}
                                <Skeleton variant="circle" width={16} height={16} sx={{ position: 'absolute', left: '-8px', top: '20px', zIndex: 2 }} />

                                <Paper
                                    sx={{
                                        padding: 2,
                                        backgroundColor: grey[300] + '20',
                                        borderLeft: `4px solid ${grey[500]}`,
                                        paddingLeft: 3,
                                        width: '100%',
                                    }}
                                    elevation={3}
                                >
                                    <Skeleton variant="text" width="80%" height={20} sx={{ marginBottom: 1 }} />
                                    <Skeleton variant="text" width="60%" height={20} sx={{ marginBottom: 1 }} />
                                    <Skeleton variant="text" width="50%" height={20} sx={{ marginBottom: 1 }} />
                                </Paper>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};
const Timeline = ({ data, loading, resetForm }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const getStepColor = (status) => {
        if (status === 'SUCCESS') return green[500];
        if (status === 'FAILED') return red[500];
        return grey[500]; // for any undefined status
    };
    const handleReset = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmReset = () => {
        setOpenDialog(false); // Close dialog
        resetForm();
    };
    return (
        <>
            {data && <Card variant="outlined" sx={{ maxWidth: 545, marginBottom: 2, marginLeft: "200px", marginTop: "20px" }}>
                <CardContent sx={{ marginLeft: "20px" }}>
                    {data && <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
                        <Typography variant="h6" sx={{ fontSize: "18px" }} color="text.primary">Customer ID: {data.customerId}</Typography>
                        <Typography variant="body2" color="text.secondary">Account Type: {data.accountType || 'N/A'}</Typography>
                        <Typography variant="body2" color="text.secondary">ID: {data.id || 'N/A'}</Typography> <br />
                        {/* Timeline container */}
                        <Box sx={{ position: 'relative', marginBottom: 4 }}>
                            {/* Vertical line */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: '8px',
                                    bottom: 0,
                                    width: '2px',
                                    backgroundColor: grey[300],
                                    zIndex: 0,
                                }}
                            ></Box>

                            {/* Steps (timeline points) */}
                            {data.steps.map((step, index) => {
                                const stepColor = getStepColor(step.status);

                                return (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginBottom: 3,
                                            position: 'relative',
                                            zIndex: 1,
                                        }}
                                    >
                                        {/* Timeline point */}
                                        <Box
                                            sx={{
                                                width: '16px',
                                                height: '16px',
                                                borderRadius: '50%',
                                                backgroundColor: stepColor,
                                                position: 'absolute',
                                                left: '-8px',
                                                top: '20px',
                                                zIndex: 2,
                                            }}
                                        ></Box>

                                        <Paper
                                            sx={{
                                                padding: 2,
                                                backgroundColor: stepColor + '20', // Lightened background color
                                                borderLeft: `4px solid ${stepColor}`,
                                                paddingLeft: 3,
                                                width: '100%',
                                            }}
                                            elevation={3}
                                        >
                                            <Typography variant="h6" sx={{ fontSize: "18px" }}>Step {index + 1}: {step.creationId}</Typography>
                                            <Tooltip title={step.errormsg} arrow>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ color: stepColor, cursor: 'pointer', marginTop: 1 }}
                                                >
                                                    {step.status}
                                                </Typography>
                                            </Tooltip>
                                            <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
                                                {step.status === 'SUCCESS' ? 'Step completed successfully' : 'Step failed'}
                                            </Typography>
                                        </Paper>
                                    </Box>
                                );
                            })}
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReset}
                            sx={{ padding: '8px 16px', marginLeft: "380px" }}
                        >
                            Reset
                        </Button>
                    </Box>}

                </CardContent>
            </Card>}

            {loading && <LoadingSkeleton />}
            <Dialog open={openDialog} onClose={handleCloseDialog} BackdropProps={{
                style: {
                    backdropFilter: 'blur(5px)', // Apply blur to the background
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Add a semi-transparent background
                },
            }}>
                <DialogTitle>Reset Confirmation</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to reset the page?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmReset} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Timeline;